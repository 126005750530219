import React from 'react'

const ArrowForwardIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 8H14" stroke="var(--accent-color)" strokeWidth="2" />
      <path d="M7 1L14 8L7 15" stroke="var(--accent-color)" strokeWidth="2" />
    </svg>
  )
}

export default ArrowForwardIcon
