import React from 'react'
import { ContentfulRichText } from 'types/contentful'
import RichText from './rich-text'

import * as styles from './terms-and-conditions.module.css'

type TermsAndConditionsProps = {
  content: ContentfulRichText
}

const TermsAndConditions = ({ content }: TermsAndConditionsProps) => {
  return (
    <section className={styles.termsAndConditions}>
      <div className="container">
        <RichText data={content} classes={styles.content} />
      </div>
    </section>
  )
}

export default TermsAndConditions
