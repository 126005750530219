import React from 'react'
import { useLandingPageHelpers } from 'hooks/use-landing-page-helpers'
import { ContentfulLandingSection } from 'types/contentful'
import SectionCallToAction from './section-call-to-action'
import SectionLinksWithImage from './section-links-with-image'
import SectionSignupForm from './section-signup-form'
import SectionText, { SectionTextVariant } from './section-text'

type SectionsProps = {
  sections: ContentfulLandingSection[]
  programSlug: string
  partnerSlug: string
  pathParam?: string | null
}

const Sections = ({
  sections,
  programSlug,
  partnerSlug,
  pathParam,
}: SectionsProps) => {
  const { showCtaSection, showFormSection } = useLandingPageHelpers(sections)

  const renderSection = (section: ContentfulLandingSection, index: number) => {
    switch (section.__typename) {
      case 'ContentfulSectionText':
        return (
          <SectionText
            key={section.id}
            variant={SectionTextVariant.Primary}
            index={index}
            {...section}
          />
        )
      case 'ContentfulSectionCallToAction':
        return showCtaSection ? (
          <SectionCallToAction
            key={section.id}
            {...section}
            programSlug={programSlug}
            partnerSlug={partnerSlug}
            pathParam={pathParam}
            index={index}
          />
        ) : null
      case 'ContentfulSectionForm':
        return showFormSection ? (
          <SectionSignupForm
            key={section.id}
            sectionForm={section}
            programSlug={programSlug}
            partnerSlug={partnerSlug}
            pathParam={pathParam}
            index={index}
          />
        ) : null
      case 'ContentfulSectionLinksWithImage':
        return (
          <SectionLinksWithImage key={section.id} index={index} {...section} />
        )
      default:
        return null
    }
  }

  if (!sections) return null

  return (
    <>
      {sections.map((section, index) => {
        return (
          <div id={`section-${index + 1}`} key={section.id}>
            {renderSection(section, index)}
          </div>
        )
      })}
    </>
  )
}

export default Sections
