import React from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

import * as styles from './hero.module.css'

type HeroProps = {
  image: IGatsbyImageData
  title: string
}

const Hero = ({ image, title }: HeroProps) => {
  return (
    <div className={styles.hero}>
      <GatsbyImage className={styles.image} alt={title} image={image} />
    </div>
  )
}

export default Hero
