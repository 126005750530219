import React, { useEffect, useState } from 'react'
import BeatLoader from 'react-spinners/BeatLoader'
import RichText from './rich-text'
import { useLayoutContext } from './layout'
import { getSignupCallbackURL } from 'lib/uwp'
import { ContentfulRichText } from 'types/contentful'
import * as styles from './reauth-section.module.css'
import * as Sentry from '@sentry/gatsby'

type ReauthSectionProp = {
  text: ContentfulRichText
  partnerSlug: string
  programSlug: string
  buttonText: string
  adjacentText: ContentfulRichText
}

const ReauthSection = ({
  text,
  partnerSlug,
  programSlug,
  buttonText,
  adjacentText,
}: ReauthSectionProp) => {
  const { accentColorHex } = useLayoutContext()
  const [callbackURL, setCallbackURL] = useState('')

  useEffect(() => {
    getSignupCallbackURL({
      partnerKey: partnerSlug,
      programKey: programSlug,
      pathParam: 'REAUTH',
    })
      .then((url) => {
        setCallbackURL(url)
      })
      .catch((error) => {
        console.error(error)
        Sentry.captureException(error)
      })
  }, [])

  return (
    <>
      <div className={styles.reauthSectionText}>
        {text && <RichText data={text} />}
      </div>
      <div className={styles.reauthSectionCallToAction}>
        <div className={styles.flex}>
          <div>
            {callbackURL.length > 0 && (
              <a href={callbackURL} className={`button ${styles.buttonLink}`}>
                {buttonText}
              </a>
            )}
            {callbackURL.length <= 0 && (
              <div className={styles.loader}>
                <BeatLoader color={accentColorHex} size={10} />
              </div>
            )}
          </div>
          <div>
            {adjacentText && (
              <RichText data={adjacentText} classes={styles.adjacentText} />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default ReauthSection
