import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import {
  ContentfulComponentImage,
  ContentfulComponentLink,
} from 'types/contentful'
import * as styles from './section-links-with-image.module.css'
import ArrowForwardIcon from './arrow-forward-icon'

type SectionLinksWithImageProps = {
  links: ContentfulComponentLink[]
  image: ContentfulComponentImage
  index: number
}

const SectionLinksWithImage = ({
  links,
  image,
  index,
}: SectionLinksWithImageProps) => {
  return (
    <div className={styles.sectionLinksWithImage} data-index={index}>
      <div className={styles.flex}>
        <div>
          <ul className={styles.listOfLinks}>
            {links.map((link) => (
              <li key={link.contentful_id}>
                <a href={link.url} className="links">
                  {link.text}
                  <ArrowForwardIcon />
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.imageWrapper}>
          <div className={styles.image}>
            {image && (
              <GatsbyImage
                alt={image.image.title}
                image={image.image.gatsbyImageData}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SectionLinksWithImage
