import { useMemo } from 'react'
import { ContentfulLandingSection } from 'types/contentful'

export const useLandingPageHelpers = (sections: ContentfulLandingSection[]) => {
  // We don't want to prevent the CTA from showing if there's no form section, and vice versa
  const hasBothSections = useMemo(() => {
    const sectionTypes = sections.map((s) => s.__typename)
    return (
      sectionTypes.includes('ContentfulSectionCallToAction') &&
      sectionTypes.includes('ContentfulSectionForm')
    )
  }, [sections])

  return {
    showCtaSection: !hasBothSections, // only show CTA if form doesn't exist
    showFormSection: true,
  }
}
