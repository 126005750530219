import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { ContentfulImage, ContentfulRichText } from 'types/contentful'
import RichText from './rich-text'
import * as styles from './section-text.module.css'

export enum SectionTextVariant {
  Primary = 'primary',
  Secondary = 'secondary',
}

type SectionTextProp = {
  variant: SectionTextVariant
  image?: ContentfulImage
  heading?: string
  text?: ContentfulRichText
  buttonText?: string
  buttonUrl?: string
  index: number
}

const SectionText = ({
  variant,
  image,
  heading,
  text,
  buttonText,
  buttonUrl,
  index,
}: SectionTextProp) => {
  return (
    <div className={styles[variant]}>
      <div className={styles.sectionText} data-index={index}>
        {image && (
          <GatsbyImage
            alt={image.title || 'Banner'}
            image={image.gatsbyImageData}
          />
        )}
        {heading && <h2>{heading}</h2>}
        {text && <RichText data={text} />}
        {buttonUrl && (
          <div className={styles.buttonWrapper}>
            <a className={styles.button} href={buttonUrl}>
              {buttonText}
            </a>
          </div>
        )}
      </div>
    </div>
  )
}

export default SectionText

export const query = graphql`
  fragment ContentfulSectionTextFields on ContentfulSectionText {
    id
    image {
      gatsbyImageData(layout: FULL_WIDTH)
    }
    heading
    text {
      raw
    }
    buttonText
    buttonUrl
  }
`
