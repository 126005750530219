import React, { useEffect, useState } from 'react'
import BeatLoader from 'react-spinners/BeatLoader'
import { Button } from './button'
import { useLayoutContext } from './layout'
import RichText from './rich-text'
import { getSignupCallbackURL } from 'lib/uwp'
import { ContentfulRichText } from 'types/contentful'
import * as styles from './section-call-to-action.module.css'
import * as Sentry from '@sentry/gatsby'

type SectionCallToActionProps = {
  partnerSlug: string
  programSlug: string
  pathParam?: string | null
  buttonText: string
  adjacentText: ContentfulRichText
  index: number
}

const SectionCallToAction = ({
  partnerSlug,
  programSlug,
  pathParam,
  buttonText,
  adjacentText,
  index,
}: SectionCallToActionProps) => {
  const { accentColorHex } = useLayoutContext()
  const [callbackURL, setCallbackURL] = useState('')

  useEffect(() => {
    if (pathParam !== undefined) {
      getSignupCallbackURL({
        partnerKey: partnerSlug,
        programKey: programSlug,
        pathParam,
      })
        .then((url) => {
          setCallbackURL(url)
        })
        .catch((error) => {
          console.error(error)
          Sentry.captureException(error)
        })
    }
  }, [pathParam])

  return (
    <div className={styles.sectionCallToAction} data-index={index}>
      <div className={styles.flex}>
        <div className={styles.flex}>
          {callbackURL.length > 0 && (
            <Button
              text={buttonText.toUpperCase()}
              url={callbackURL}
              variant="primary"
              nowrap
            />
          )}
          {callbackURL.length <= 0 && (
            <div className={styles.loader}>
              <BeatLoader color={accentColorHex} size={10} />
            </div>
          )}
        </div>
        <div>
          {adjacentText && (
            <RichText data={adjacentText} classes={styles.adjacentText} />
          )}
        </div>
      </div>
    </div>
  )
}

export default SectionCallToAction
