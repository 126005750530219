import React, { useEffect, useState } from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout'
import Hero from 'components/hero'
import Sections from 'components/sections'
import TermsConditions from 'components/terms-and-conditions'
import ReauthSection from 'components/reauth-section'
import {
  ContentfulComponentHeader,
  ContentfulComponentImage,
  ContentfulLayout,
  ContentfulReAuthCta,
  ContentfulReAuthDesc,
  ContentfulRichText,
  ContentfulLandingSection,
  ContentfulSEO,
} from 'types/contentful'

type DataProps = {
  contentfulPageLanding: {
    layout: ContentfulLayout
    header: ContentfulComponentHeader
    heading: string
    heroImage: ContentfulComponentImage
    sections: ContentfulLandingSection[]
    termsAndConditions: ContentfulRichText
    reauthHeading: string
    reauthDesc: ContentfulReAuthDesc
    reauthCta: ContentfulReAuthCta
    partnerSlug: string
    programSlug: string
    seo: ContentfulSEO
  }
}

const IndexPage: React.FC<PageProps<DataProps>> = (props) => {
  const {
    partnerSlug,
    programSlug,
    layout,
    heading,
    header,
    heroImage,
    sections,
    termsAndConditions,
    reauthHeading,
    reauthDesc,
    reauthCta,
    seo,
  } = props.data.contentfulPageLanding
  const [flowParam, setFlowParam] = useState<string | null>()

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    setFlowParam(urlParams.get('path'))
  }, [])

  return (
    <Layout layout={layout} header={header} seo={seo}>
      <div className="container">
        {flowParam?.toUpperCase() === 'REAUTH' && reauthDesc ? (
          <>
            <h1>{reauthHeading}</h1>
            {heroImage && (
              <Hero
                image={heroImage.image.gatsbyImageData}
                title={heroImage.image.title}
              />
            )}
            <ReauthSection
              text={reauthDesc.text}
              partnerSlug={partnerSlug}
              programSlug={programSlug}
              buttonText={reauthCta.buttonText}
              adjacentText={reauthCta.adjacentText}
            />
          </>
        ) : (
          <>
            <h1>{heading}</h1>
            {heroImage && (
              <Hero
                image={heroImage.image.gatsbyImageData}
                title={heroImage.image.title}
              />
            )}
            <Sections
              sections={sections}
              partnerSlug={partnerSlug}
              programSlug={programSlug}
              pathParam={flowParam}
            />
          </>
        )}
      </div>
      <TermsConditions content={termsAndConditions} />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query HomeQuery($contentful_id: String!) {
    contentfulPageLanding(contentful_id: { eq: $contentful_id }) {
      contentful_id
      partnerSlug
      programSlug
      heading
      layout {
        ...LayoutFields
      }
      header {
        ...HeaderFields
      }
      heroImage {
        image {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          title
        }
      }
      sections {
        __typename
        ... on ContentfulSectionText {
          ...ContentfulSectionTextFields
        }
        ... on ContentfulSectionCallToAction {
          id
          buttonText
          adjacentText {
            raw
          }
        }
        ... on ContentfulSectionLinksWithImage {
          id
          links {
            contentful_id
            text
            url
          }
          image {
            image {
              gatsbyImageData(placeholder: NONE)
              title
            }
          }
        }
        ... on ContentfulSectionForm {
          ...ContentfulSectionFormFields
        }
      }
      termsAndConditions {
        raw
      }
      reauthHeading: reauthenticationHeading
      reauthDesc: reauthenticationDescription {
        text {
          raw
        }
      }
      reauthCta: reauthenticationCta {
        id
        buttonText
        adjacentText {
          raw
        }
      }
      seo {
        ...SeoFields
      }
    }
  }
`
